import React from 'react';
import {
  BrowserRouter as Router,
  Link,
} from "react-router-dom";

const FourOhFour = () => {

  return (
    <div className="four-oh-four-wrapper">
      <h1>Woopsy Doozy</h1>
      <h3>This page doesn't exist</h3>
      <Link to="/">Go back to the home page</Link>
    </div>
  )

}

export default FourOhFour;
