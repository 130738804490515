import React, {useEffect, useState} from 'react';
import axios from 'axios';
import moment from 'moment';
import dashFilters from '../utils/dashFilters';
import Pin from './svg/Pin'
import Web from './svg/Web'
import Phone from './svg/Phone'
const UserDash = () => {

  const [user, setUser] = useState({
    isAuth: false,
  })
  const [resources, setResources] = useState([]);
  const [filteredResources, setFilteredResources] = useState({
    label: '',
    resources: [],
  });
  useEffect(() => {
    window.scrollTo(0, 0);
    axios.post('/user-dashboard', {}, {
      headers: {
        'Authorization' : `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then((res) => {
      console.log(res.data)
      if (res.data.profile.user) {
        setUser(u => ({...u, isAuth: true, ...res.data.profile}));
        setResources([...res.data.resources]);

      }
    });
  }, [])
  const handleFilter = (filter) => {
    const allResources = [...resources];
    console.log(allResources);
    const filtered = allResources.filter(r => {
      let matched = false;
      r.categories.forEach(c => {

        if (filter.categories.includes(c)) {
          matched = true;
        }
      })
      return matched;
    })
    setFilteredResources(r => ({...r, label: filter.label, resources: filtered}));
  }
  return (
    <div className="dash-wrapper">
      {user.isAuth ? (
        <div className="user-dashboard">
          <div className="sidebar">
            <div className="user-image">
              <img src="https://picsum.photos/200/200" alt={`${user.user_data.first_name} ${user.user_data.last_name}`}/>
            </div>
            <div className="sb-group">
              <h3>{`${user.user_data.contact.first_name.value} ${user.user_data.contact.last_name.value}`}</h3>
            </div>
            <div className="info-block">
              <p className="label">D.O.B.</p>
              <p>{moment(user.user_data.personal.dob.value).format('l')}</p>
            </div>
            <div className="info-block">
              <p className="label">Gender</p>
              <p>{user.user_data.personal.gender.value}</p>
            </div>
            <div className="info-block">
              <p className="label">Race</p>
              <p>{user.user_data.demographic.race.value}</p>
            </div>
            <div className="info-block">
              <p className="label">SSN</p>
              <p>{user.user_data.personal.ssn.value}</p>
            </div>
            <div className="info-block">
              <p className="label">Insurance</p>
              <p>{user.user_data.insurance.insurance_type.value}</p>
            </div>
            <div className="info-block">
              <p className="label">Medical Conditions</p>
              <ul>
              {Object.keys(user.user_data.medical).map(need => (
                user.user_data.medical[need].value ? (<li key={need}>{user.user_data.medical[need].label}</li>) : null
              ))}
              </ul>
            </div>
            <div className="info-block">
              <p className="label">Mental Health Conditions</p>
              <ul>
              {Object.keys(user.user_data.mental_health).map(need => (
                user.user_data.mental_health[need].value ? (<li key={need}>{user.user_data.mental_health[need].label}</li>) : null
              ))}
              </ul>
            </div>
          </div>
          <main className="profile-main">
              <h3 className="dash-heading">Overview</h3>
              <div className="profile-box medical-box white-box">
                <div className="profile-box-header">
                  <h3>Contact Information</h3>
                </div>
                <div className="profile-box-body">
                 <div className="info-group">
                   <h5 className="label">Address</h5>
                  <p>{user.user_data.contact.address_1.value} <br />
                  {user.user_data.contact.address_2.value ? (
                    <span>
                      {user.user_data.contact.address_2.value} <br />
                    </span>
                    ) : null}
                  {user.user_data.contact.city.value}, {user.user_data.contact.state.value} {user.user_data.contact.zip.value}</p>
                 </div>
                 <div className="info-group">
                   <h5 className="label">Phone</h5>
                    {/* <p>{user.user_data.contact.phone.value}</p> */}
                 </div>
                  <div className="info-group">
                   <h5 className="label">Email</h5>
                    {/* <p>{user.user_data.contact.email.value}</p> */}
                 </div>
                </div>
                <div className="profile-box-group filter-box">
                  {dashFilters.map(df => {
                    return (
                      <button
                      key={df.key}
                      onClick={() => handleFilter(df)}
                      className="filter-button">
                        <div className="filter-circle">
                          <span>
                            {df.icon}
                          </span>
                        </div>
                        <span className="filter-label">
                          {df.label}
                        </span>
                      </button>
                    )
                  })}
                </div>
              </div>

              <h3 className="dash-heading">Recommended Services <span className="filter-tag">{filteredResources.label}</span></h3>
              {filteredResources.resources.map(r => (
                <div
                key={r.key}
                className="profile-box white-box resource-listing">
                  <div className="left-side">
                    <h3>{r.title}</h3>
                    <p>{r.description}</p>
                    <div className="categories">
                      {r.categories.map(c => (
                        user.user_data.assistance[c] ? <span className="cat-tag">{user.user_data.assistance[c].label}</span> : null
                      ))
                      }
                    </div>
                  </div>
                  <div className="right-side">
                    <ul>
                      <li>
                        <Phone />
                        <span>{r.contact.phone}</span>
                      </li>
                      <li>
                        <Web />
                        <span className="url">{r.contact.website}</span>
                      </li>
                      <li>
                        <Pin/>
                        <span>{r.contact.address}</span>
                      </li>
                    </ul>
                  </div>
                </div>
              ))}

          </main>
        </div>
      ) : (
        <h1>Unauthorized</h1>
      )}

    </div>

  )

}

export default UserDash;
