const formData = () => {
  const formGroups = [
    {
      title: 'Contact Information',
      key: 'contact',
      rows: [
        {
          fields: [
            {
              label: 'First Name',
              type: 'text',
              key: 'first_name',
              value: '',
            },
            {
              label: 'Last Name',
              type: 'text',
              key: 'last_name',
              value: '',
            },
          ],
        },
        {
          fields: [
            {
              label: 'Is the client Homeless?',
              type: 'select',
              key: 'homeless',
              options: ['','Yes','No'],
              values: ['', true, false],
            },
            {
              label: 'Date that the client became homeless',
              type: 'date',
              key: 'homeless_date',
              value: '',
              conditional: () => formGroups[0].rows[1].fields[0].value,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Address',
              type: 'text',
              key: 'address_1',
              value: '',
            },
            {
              label: 'Address Line 2',
              type: 'text',
              key: 'address_2',
              value: '',
            },
          ],
        },
        {
          fields: [
            {
              label: 'City',
              type: 'text',
              key: 'city',
              value: '',
            },
            {
              label: 'State',
              type: 'text',
              key: 'state',
              value: '',
            },
            {
              label: 'Zip',
              type: 'text',
              key: 'zip',
              value: '',
            },
          ],
        },
        {
          fields: [
            {
              label: 'Phone',
              type: 'text',
              key: 'phone',
              value: '',
            },
            {
              label: 'Email',
              type: 'text',
              key: 'email',
              value: '',
            },
          ],
        },
      ],
    },
    {
      title: 'Personal Verifying Info',
      key: 'personal',
      rows: [
        {
          fields: [
            {
              label: 'DOB',
              type: 'date',
              key: 'dob',
              value: '',
            },
            {
              label: 'SSN',
              type: 'text',
              key: 'ssn',
              value: '',
            },
            {
              label: 'Gender',
              type: 'select',
              key: 'gender',
              value: '',
              options: [
                '',
                'Female',
                'Male',
                'Nonbinary',
                'Transgender',
                'Prefer Not To Answer',
              ]
            },
          ],
        },
      ],
    },
    {
      title: 'Other Demographic Information',
      key: 'demographic',
      rows: [
        {
          fields: [
            {
              label: 'Sexuality',
              type: 'select',
              key: 'sexuality',
              value: '',
              options: [
                '',
                'Heterosexual',
                'Homosexual',
                'Bisexual',
                'Asexual',
                'Prefer Not To Answer',
              ],
            },
            {
              label: 'Status',
              type: 'select',
              key: 'status',
              value: '',
              options: [
                '',
                'Single',
                'Married',
                'Widowed',
                'Domestic Partnership',
              ],
            },
            {
              label: 'Race/Ethnicity',
              type: 'select',
              key: 'race',
              value: '',
              options: [
                '',
                'African-American',
                'Caucasian',
                'Asian',
                'Race/Latinx',
                'Native American',
                'Multiracial',
                'Pacific Islander',
                'Other',
                'Prefer Not To Answer',
              ],
            },
            {
              label: 'Primary Language',
              type: 'select',
              key: 'primary_language',
              value: '',
              options: ['', 'English', 'Spanish', 'Spanish/English'],
            },
          ],
        },
      ],
    },
    {
      title: 'Emergency Contact',
      key: 'emergency_contact',
      rows: [
        {
          fields: [
            {
              label: 'Relationship',
              type: 'select',
              value: '',
              key: 'contact_relationship',
              options: ['', 'Parent', 'Sibling', 'Spouse', 'Friend', 'Other'],
            },
            {
              label: 'Name',
              type: 'text',
              value: '',
              key: 'contact_name',
            },
            {
              label: 'Phone',
              type: 'text',
              value: '',
              key: 'emergency_phone',
            },
          ],
        },
      ],
    },
    {
      title: 'Military / Veteran Information',
      key: 'military',
      rows: [
        {
          fields: [
            {
              label: 'Is the client a veteran?',
              type: 'select',
              key: 'veteran',
              options: ['', 'Yes', 'No'],
            },
            {
              label: 'Client Currently Receives Services From The VA',
              type: 'select',
              key: 'va_services',
              options: ['', 'Yes', 'No']
            },
            {
              label: 'Has Military Related Condition',
              type: 'checkbox',
              key: 'military_conditions',
              value: false,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Other Information on Military Service Related Condition',
              type: 'text',
              key: 'military_conditions_info',
              value: '',
            },
          ],
        },
      ],
    },
    {
      title: 'Insurance Information',
      key: 'insurance',
      rows: [
        {
          fields: [
            {
              label: 'Has Insurance',
              type: 'select',
              key: 'has_insurance',
              options: ['', 'Yes', 'No'],
            },
            {
              label: 'Insurance Type',
              type: 'select',
              key: 'insurance_type',
              options: ['', 'Medicaid', 'Medicare', 'Commercial'],
            },
            {
              label: 'Medical Insurance ID#',
              type: 'text',
              key: 'insurance_id',
              value: '',
            },
          ],
        },
        {
          fields: [
            {
              label: 'Additional Notes',
              type: 'text',
              key: 'insurance_info',
              value: '',
            },
          ],
        },
      ],
    },
    {
      title: 'Current Medical Condition',
      key: 'medical',
      rows: [
        {
          fields: [
            {
              label: 'Does the client have any physical disabilities?',
              type: 'checkbox',
              key: 'disabilities',
              value: false,
            },
            {
              label: 'Diasability Info',
              type: 'text',
              key: 'disability_info',
              value: '',
            },
          ],
        },
        {
          fields: [
            {
              label: 'Receiving Medical Treatment',
              type: 'select',
              key: 'medical_treatment',
              value: '',
              options: ['', 'Yes', 'No'],
            },
          ],
        },
        {
          fields: [
            {
              label: 'Hypertension',
              type: 'checkbox',
              key: 'hypertension',
              value: false,
            },
            {
              label: 'Cholesterol',
              type: 'checkbox',
              key: 'cholesterol',
              value: false,
            },
            {
              label: 'Thyroid problems',
              type: 'checkbox',
              key: 'thyroid',
              value: false,
            },
            {
              label: 'Acid reflux',
              type: 'checkbox',
              key: 'acid_reflux',
              value: false,
            },
            {
              label: 'Seasonal allergies',
              type: 'checkbox',
              key: 'allergies',
              value: false,
            },
            {
              label: 'Diabetes',
              type: 'checkbox',
              key: 'diabetes',
              value: false,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Cancer',
              type: 'checkbox',
              key: 'cancer',
              value: false,
            },
            {
              label: 'Seizures',
              type: 'checkbox',
              key: 'seizures',
              value: false,
            },
            {
              label: 'Pregnancy',
              type: 'checkbox',
              key: 'pregnancy',
              value: false,
            },
            {
              label: 'Back pain',
              type: 'checkbox',
              key: 'back_pain',
              value: false,
            },
            {
              label: 'Respiratory issues',
              type: 'checkbox',
              key: 'respiratory_issues',
              value: false,
            },
            {
              label: 'COPD',
              type: 'checkbox',
              key: 'copd',
              value: false,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Asthma',
              type: 'checkbox',
              key: 'asthma',
              value: false,
            },
            {
              label: 'Obesity',
              type: 'checkbox',
              key: 'obesity',
              value: false,
            },
            {
              label: 'Kidney disease',
              type: 'checkbox',
              key: 'kidney_disease',
              value: false,
            },
            {
              label: 'Hearing loss',
              type: 'checkbox',
              key: 'hearing_loss',
              value: false,
            },
            {
              label: 'Vision loss',
              type: 'checkbox',
              key: 'vision_loss',
              value: false,
            },
            {
              label: 'Weight loss/gain',
              type: 'checkbox',
              key: 'weight',
              value: false,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Skin issues/Open wounds',
              type: 'checkbox',
              key: 'skin_issues',
              value: false,
            },
            {
              label: 'Brain injury',
              type: 'checkbox',
              key: 'brain_injury',
              value: false,
            },
            {
              label: 'Hepatitis',
              type: 'checkbox',
              key: 'hepatitis',
              value: false,
            },
            {
              label: 'HIV/AIDS',
              type: 'checkbox',
              key: 'hivaids',
              value: false,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Other Medical Conditions',
              type: 'text',
              key: 'other_medical',
              value: '',
            },
          ],
        },
      ],
    },
    {
      title: 'Mental Health Condition',
      key: 'mental_health',
      rows: [
        {
          fields: [
            {
              label: 'Does the client have any mental health conditions?',
              type: 'select',
              key: 'psych_condition',
              value: '',
              options: ['', 'Yes', 'No'],
            },
          ],
        },
        {
          fields: [
            {
              label: 'Schizophrenia',
              type: 'checkbox',
              key: 'schizophrenia',
              value: false,
            },
            {
              label: 'Schizoaffective disorder',
              type: 'checkbox',
              key: 'schizoaffective',
              value: false,
            },
            {
              label: 'Bipolar disorder',
              type: 'checkbox',
              key: 'bipolar',
              value: false,
            },
            {
              label: 'Anxiety disorders',
              type: 'checkbox',
              key: 'anxiety',
              value: false,
            },
            {
              label: 'Obsessive-compulsive disorder',
              type: 'checkbox',
              key: 'ocd',
              value: false,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Dissociative disorder',
              type: 'checkbox',
              key: 'dissociative',
              value: false,
            },
            {
              label: 'Eating disorder',
              type: 'checkbox',
              key: 'eating_disorder',
              value: false,
            },
            {
              label: 'ADHD',
              type: 'checkbox',
              key: 'adhd',
              value: false,
            },
            {
              label: 'PTSD',
              type: 'checkbox',
              key: 'ptsd',
              value: false,
            },
            {
              label: 'Autism',
              type: 'checkbox',
              key: 'autism',
              value: false,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Borderline Personality Disorder',
              type: 'checkbox',
              key: 'bpd',
              value: false,
            },
          ],
        },
      ],
    },
    {
      title: 'Substance Abuse',
      key: 'substance_abuse',
      rows: [
        {
          fields: [
            {
              label: 'Does the client have any substance abuse conditions?',
              type: 'select',
              key: 'substance_abuse',
              value: '',
              options: ['', 'Yes', 'No'],
            },
          ],
        },
        {
          fields: [
            {
              label: 'Alcohol',
              type: 'checkbox',
              key: 'alcohol',
              value: false,
            },
            {
              label: 'Opiods',
              type: 'checkbox',
              key: 'opioids',
              value: false,
            },
            {
              label: 'Cannabis',
              type: 'checkbox',
              key: 'cannabis',
              value: false,
            },
            {
              label: 'Sedatives',
              type: 'checkbox',
              key: 'sedatives',
              value: false,
            },
            {
              label: 'Cocaine',
              type: 'checkbox',
              key: 'cocaine',
              value: false,
            },
            {
              label: 'Other stimulants',
              type: 'checkbox',
              key: 'stimulants',
              value: false,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Hallucinogens',
              type: 'checkbox',
              key: 'hallucinogens',
              value: false,
            },
            {
              label: 'Nicotine dependence',
              type: 'checkbox',
              key: 'nicotine',
              value: false,
            },
            {
              label: 'Inhalant related disorders',
              type: 'checkbox',
              key: 'inhalants',
              value: false,
            },
            {
              label: 'Other',
              type: 'checkbox',
              key: 'other_substance',
              value: false,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Additional Comments',
              type: 'text',
              key: 'substance_abuse_comments',
              value: '',
            },
          ],
        },
        {
          fields: [
            {
              label: 'Date Last Used',
              type: 'text',
              key: 'substance_last_used',
              value: '',
            },
          ],
        },
        {
          fields: [
            {
              label: 'Receiving Treatment?',
              type: 'checkbox',
              key: 'substance_treatment',
              value: false,
            },
            {
              label: 'Requesting Assistance?',
              type: 'checkbox',
              key: 'substance_assistance',
              value: false,
            },
            {
              label: 'Does the client have any prescribed medication?',
              type: 'select',
              key: 'rx',
              value: '',
              options: ['', 'Yes', 'No'],
            },
            {
              label: 'If yes, taken as prescribed?',
              type: 'select',
              key: 'rx_taken_as_prescribed',
              value: '',
              options: ['', 'Yes', 'No'],
            },
            {
              label: 'Is the client requesting prescription assistance?',
              type: 'select',
              key: 'rx_assistance',
              value: '',
              options: ['', 'Yes', 'No'],
            },
          ],
        },
      ],
    },
    {
      title: 'Felony History',
      key: 'crime',
      rows: [
        {
          fields: [
            {
              label: 'Does the client have a history of felony convictions?',
              type: 'checkbox',
              key: 'felony_history',
              value: false,
            },
            {
              label: 'If yes, were any within the past 7 years?',
              type: 'select',
              key: 'felony_recent',
              value: '',
              options: ['', 'Yes', 'No'],
            },
            {
              label: 'Please Explain',
              type: 'text',
              key: 'felony_info',
              value: '',
            },
          ],
        },
      ],
    },
    {
      title: 'Housing',
      key: 'housing',
      rows: [
        {
          fields: [
            {
              label: 'Does the client hold a housing voucher?',
              type: 'select',
              key: 'housing_voucher',
              value: '',
              options: ['', 'Yes', 'No', 'Lifetime'],
            },
            {
              label: 'If yes, from where?',
              type: 'text',
              key: 'voucher_issuer',
              value: '',
            },
            {
              label: 'Type of voucher?',
              type: 'text',
              key: 'voucher_type',
              value: '',
            },
          ],
        },
        {
          fields: [
            {
              label: 'Is the client currently employed?',
              type: 'select',
              key: 'employment_status',
              value: '',
              options: [
                '',
                'Full Time',
                'Part Time',
                'Contract',
                'Temporary',
                'Unemployed',
              ],
            },
            {
              label: 'Monthly Income',
              type: 'select',
              key: 'income_type',
              value: '',
              options: ['', 'Job', 'SSI', 'SSDI', 'Other'],
            },
            {
              label: 'Enter Monthly Amount',
              type: 'text',
              key: 'income_value',
              value: '',
            },
          ],
        },
        {
          fields: [
            {
              label: 'History of Evictions?',
              type: 'select',
              key: 'eviction_history',
              value: '',
              options: ['', 'Yes', 'No'],
            },
            {
              label: 'If yes, how many?',
              type: 'text',
              key: 'evictions',
              value: '',
            },
            {
              label: 'How many years ago?',
              type: 'text',
              key: 'eviction_recent',
              value: '',
            },
          ],
        },
        {
          fields: [
            {
              label: 'Does the client require assistance with utilities?',
              type: 'checkbox',
              key: 'utilities_assistance',
              value: false,
            },
            {
              label: 'If yes, explain',
              type: 'text',
              key: 'utilities_info',
              value: '',
            },
          ],
        },
      ],
    },
    {
      title: 'Community Resource Assistance',
      key: 'assistance',
      rows: [
        {
          fields: [
            {
              label:
                'What types of community resources, if any, is the client currently receiving? (Exampled: food stamps, discounted bus fare)',
              type: 'text',
              key: 'community_assistance',
              value: '',
            },
          ],
        },
        {
          fields: [
            {
              label: 'Case Help',
              type: 'checkbox',
              key: 'case_help',
              value: false,
            },
            {
              label: 'Clothing',
              type: 'checkbox',
              key: 'clothing',
              value: false,
            },
            {
              label: 'Documentation',
              type: 'checkbox',
              key: 'documentation',
              value: false,
            },
            {
              label: 'Shelter',
              type: 'checkbox',
              key: 'shelter',
              value: false,
            },
            {
              label: 'Employment',
              type: 'checkbox',
              key: 'employment',
              value: false,
            },
            {
              label: 'Food',
              type: 'checkbox',
              key: 'food',
              value: false,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Food Stamps',
              type: 'checkbox',
              key: 'food_stamps',
              value: false,
            },
            {
              label: 'Housing',
              type: 'checkbox',
              key: 'housing',
              value: false,
            },
            {
              label: 'Medical Care',
              type: 'checkbox',
              key: 'medical_care',
              value: false,
            },
            {
              label: 'Medical Insurance',
              type: 'checkbox',
              key: 'medical_insurance',
              value: false,
            },
            {
              label: 'Mental Health',
              type: 'checkbox',
              key: 'mental_health',
              value: false,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Relationships',
              type: 'checkbox',
              key: 'relationships',
              value: false,
            },
            {
              label: 'Substance Abuse',
              type: 'checkbox',
              key: 'addiction',
              value: false,
            },
            {
              label: 'Support Groups',
              type: 'checkbox',
              key: 'support_groups',
              value: false,
            },
            {
              label: 'Family',
              type: 'checkbox',
              key: 'family',
              value: false,
            },
            {
              label: 'Transportation',
              type: 'checkbox',
              key: 'transportation',
              value: false,
            },
            {
              label: 'Utilities',
              type: 'checkbox',
              key: 'utilities',
              value: false,
            },
          ],
        },
        {
          fields: [
            {
              label: 'Has the client experienced domestic violence?',
              type: 'select',
              key: 'domviolence',
              value: '',
              options: ['', 'Yes', 'No', 'Prefer Not To Answer'],
            },
            {
              label: 'If yes, please explain',
              type: 'text',
              key: 'domviolence_info',
              value: '',
            },
          ],
        },
        {
          fields: [
            {
              label: 'Are you requesting police assistance?',
              type: 'select',
              key: 'domviolence_help',
              value: '',
              options: ['', 'Yes', 'No'],
            },
          ],
        },
      ],
    },
  ];

  // {
  //   title: 'Emergency Contact',
  //   rows: [
  //     {
  //       fields: [
  //         {
  //           label: 'Testing Select field',
  //           type: 'select',
  //           value: '',
  //           key: 'test',
  //           options: [
  //             'one option',
  //             'two option',
  //             'three option',
  //           ]
  //         },
  //         {
  //           label: 'Testing Select field',
  //           type: 'select',
  //           value: '',
  //           key: 'test2',
  //           options: [
  //             'one option',
  //             'two option',
  //             'three option',
  //           ]
  //         }
  //       ]
  //     }
  //   ]
  // }

  return formGroups;
};

export default formData;
