import React from 'react';
import './App.scss';
import Header from './components/Header.js';
import Login from './components/Login.js';
import UserDash from './components/UserDash.js';
import IntakeForm from './components/IntakeForm.js';
import Consent from './components/Consent.js';
import Explainer from './components/Explainer.js';
import CovidBanner from './components/CovidBanner.js';
import Footer from './components/Footer.js';
import FourOhFour from './components/FourOhFour.js';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";


function App() {
  return (
    <div className="App">
      <Router>
        <Header />
        <div className="app-inner">
          <Switch>
            <Route exact path="/">
              <section className="login-page">
                <div className="login-wrapper">
                  <div className="banner">
                    <h1>
                      <span>Welcome to the </span>
                      <span className="future">future</span>
                      <span>of community</span>
                      <span>health care</span>
                    </h1>
                    <h4>
                      Access to hundreds of services for those who need them most in the greater Kansas City Area.
                    </h4>
                  </div>
                  <Login />
                </div>
              </section>
              <Explainer />
              <CovidBanner />
            </Route>
            <Route exact path="/dashboard">
              <UserDash />
            </Route>
            <Route exact path="/form">
                <IntakeForm />
              </Route>
            <Route exact path="/consent">
                <Consent />
              </Route>
            <Route path="/">
              <FourOhFour />
            </Route>
          </Switch>
        </div>
      </Router>
      <Footer />
    </div>
  );
}

export default App;
