import React, {useEffect, useRef} from 'react';
import {gsap} from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
gsap.registerPlugin(ScrollTrigger);
const Explainer = () => {
  const revealRefs = useRef([]);
  revealRefs.current = [];

  const addToRefs = el => {
    if (el && !revealRefs.current.includes(el)) {
      revealRefs.current.push(el);
    }
  }
  useEffect(() => {
    revealRefs.current.forEach((el, index) => {

      gsap.fromTo(el, {
          autoAlpha: 0
      }, {
          duration: 1,
          autoAlpha: 1,
          ease: 'none',
          scrollTrigger: {
              id: `section-${index+1}`,
              trigger: el,
              start: 'top center+=100',
              toggleActions: 'play none none reverse'
          }
      });

  });
  }, [])
  return (
    <section className="illustrations">
              <div className="left-right" ref={addToRefs}>
                <div className="left image">
                  <img src="intake.svg" alt="intake form"/>
                </div>
                <div className="right content">
                  <h3>
                    <span>Intuitive</span> Intake Process
                  </h3>
                  <p>
                  The JOYNN ecosystem has followed best practices in developing our intake form(s) that collect vital information into a central hub. This information is immediately available to everyone in the system. Our system then takes and the assesses the needs of each intake and searches our database for the optimal services warranted.
                  </p>
                </div>
              </div>
              <div className="left-right content-left" ref={addToRefs}>
                <div className="left content">
                  <h3><span>Interactive</span> Resource Guide</h3>
                  <p>
                  JOYNN Resource Guide is updated monthly to provide accurate information to those seeking data. Our strict guidelines on criteria and followup allows our client to ensure each organization and agency is complaint with our protocol.
                  </p>
                </div>
                <div className="right image">
                  <img src="resource.svg" alt="resource guide"/>
                </div>
              </div>
              <div className="left-right" ref={addToRefs}>
                <div className="left image">
                <img src="connect.svg" alt="connectivity"/>
                </div>
                <div className="right content">
                  <h3><span>Real Time</span> Connectivity</h3>
                  <p>
                  JOYNN is able to further process the information for insights to alert the appropriate organizations using a multidirectional notification system including SMS, email, and push notifications. At the same time, the client receives a SMS confirmation of their addition to the network. Now that everyone is alerted, every agency with available resources can either Accept or Deny the referral.
                  </p>
                </div>
              </div>

            </section>
  )
}

export default Explainer;
