import React, {useState} from 'react'
import { Redirect } from 'react-router-dom'

import axios from 'axios';

const Login = () => {

  const [type, setType] = useState('register');
  const [registered, setRegistered] = useState(false);
  const [loggedIn, setLoggedIn] = useState(false);
  const [formData, setFormData] = useState({
    email: '',
    password: '',
    confirmPassword: ''
  });

  const sendUserData = (e) => {
    e.preventDefault();
    if (type === 'register') {
      axios.post('/user', formData)
      .then((res) => {
        console.log(res.data);
        if (res.data.token) {
          localStorage.setItem('token', res.data.token);
          setRegistered(true);
        }
      });
    }
    else if (type === 'login') {
      axios.post('/user/login', formData)
      .then((res) => {
        console.log(res.data);
        if (res.data.token) {
          localStorage.setItem('token', res.data.token);
          setLoggedIn(true);
        }
      })
    }
  }
  if (registered) {
    return <Redirect to="/consent" />
  }
  if (loggedIn) {
    return <Redirect to='/dashboard' />
  }
  return (
    <div className="login-box">
    <div className="type toggle">
      {type === 'register' ? (
        <button
        onClick={() => {setType('login')}}
        className="switch-type">
          Already a member? <span className="highlight"> Log in </span>
        </button>
        ) : (
        <button
        onClick={() => {setType('register')}}
        className="switch-type">
          Not a member? <span className="highlight">Register</span>
        </button>
      )}
    </div>
    {(type === 'login') ? (
      <form className="login-form">
        <h2>Login</h2>
        <div className="input-block">
          <label htmlFor="login-email">Email</label>
          <input
          onInput={(e) => {setFormData({...formData, email: e.target.value})}}
          type="email"
          name="email"
          id="login-email"/>
        </div>
        <div className="input-block">
          <label htmlFor="login-password">Password</label>
          <input
          onInput={(e) => {setFormData({...formData, password: e.target.value})}}
          type="password"
          name="password"
          id="login-password"/>
        </div>
        <div className="form-footer">
          <button
          onClick={sendUserData}
          className="login-button">Login</button>
        </div>
      </form>
    ) : (
      <form className="register-form">
        <h2>Register</h2>
        <div className="input-block">
          <label htmlFor="register-email">Email</label>
          <input
          onInput={(e) => {setFormData({...formData, email: e.target.value})}}
          type="email" name="email" id="register-email"/>
        </div>
        <div className="input-block">
          <label htmlFor="register-password">Password</label>
          <input
          onInput={(e) => {setFormData({...formData, password: e.target.value})}}
          type="password" name="password" id="register-password"/>
        </div>
        <div className="input-block">
          <label htmlFor="confirm-password">Confirm Password</label>
          <input
          onInput={(e) => {setFormData({...formData, confirmPassword: e.target.value})}}
          type="password" name="confirm-password" id="confirm-password"/>
        </div>
        <div className="form-footer">
          <button
          onClick={sendUserData}
          className="register-button">Register</button>
        </div>
      </form>
    )}
    </div>
  );
}

export default Login;
