import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="29"
      fill="none"
      viewBox="0 0 29 29"
    >
      <path
        fill="#706969"
        d="M19.768 16.917c.097-.798.17-1.595.17-2.417 0-.822-.073-1.62-.17-2.417h4.084a9.96 9.96 0 01.315 2.417c0 .834-.121 1.643-.315 2.417h-4.084zm-2.139 6.718a18.908 18.908 0 001.668-4.302h3.565a9.702 9.702 0 01-5.232 4.302zm-.302-6.718h-5.655a16.024 16.024 0 01-.193-2.417c0-.822.073-1.631.194-2.417h5.655c.108.786.193 1.595.193 2.417 0 .822-.085 1.62-.194 2.417zM14.5 24.118c-1.003-1.45-1.813-3.057-2.308-4.785h4.616c-.495 1.728-1.305 3.335-2.308 4.785zM9.667 9.667H6.138a9.574 9.574 0 015.22-4.302c-.725 1.341-1.269 2.791-1.691 4.302zm-3.529 9.666h3.529c.422 1.51.966 2.96 1.691 4.302a9.676 9.676 0 01-5.22-4.302zm-.99-2.416a9.96 9.96 0 01-.315-2.417c0-.834.121-1.643.314-2.417h4.085c-.097.798-.17 1.595-.17 2.417 0 .822.073 1.62.17 2.417H5.147zM14.5 4.87c1.003 1.45 1.813 3.069 2.308 4.797h-4.616c.495-1.728 1.305-3.347 2.308-4.797zm8.362 4.797h-3.565a18.903 18.903 0 00-1.668-4.302 9.65 9.65 0 015.233 4.302zM14.5 2.417C7.818 2.417 2.417 7.854 2.417 14.5A12.083 12.083 0 1014.5 2.417z"
      ></path>
    </svg>
  );
}

export default Icon;