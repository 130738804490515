import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="34"
      height="34"
      fill="none"
      viewBox="0 0 34 34"
    >
      <path
        fill="#706969"
        d="M17 3.415c-4.695 0-8.5 3.79-8.5 8.483 0 8.116 8.5 18.7 8.5 18.7s8.5-10.586 8.5-18.7c0-4.692-3.805-8.483-8.5-8.483zm0 13.177a4.59 4.59 0 110-9.18 4.59 4.59 0 010 9.18z"
      ></path>
    </svg>
  );
}

export default Icon;