import React from "react";

function Icon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="23"
      height="23"
      fill="none"
      viewBox="0 0 23 23"
    >
      <path
        fill="#706969"
        d="M9.724 13.187a13.015 13.015 0 004.728 3.06l2.408-1.917a.428.428 0 01.486 0l4.472 2.882a1.276 1.276 0 01.21 2.031l-2.095 2.07a2.556 2.556 0 01-2.319.684 22.418 22.418 0 01-10.957-5.75A21.588 21.588 0 01.735 5.494a2.415 2.415 0 01.703-2.268L3.61 1.131a1.278 1.278 0 011.993.204L8.58 5.75a.402.402 0 010 .48L6.62 8.586a12.618 12.618 0 003.105 4.6z"
      ></path>
    </svg>
  );
}

export default Icon;