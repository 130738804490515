const dashFilters = () => {
  const filters = [
    {
      key: 'family',
      label: 'Family',
      categories: ['family', 'relationships'],
      icon: '👫'
    },
    {
      key: 'case_help',
      label: 'Case Help',
      categories: ['case_help', 'legal'],
      icon: '📞'
    },
    {
      key: 'documentation',
      label: 'Documentation',
      categories: ['documentation', 'navigating_the_system'],
      icon: '📝'
    },
    {
      key: 'housing',
      label: 'Housing',
      categories: ['housing', 'shelter'],
      icon: '🏠'
    },
    {
      key: 'clothing',
      label: 'Clothing',
      categories: ['clothing'],
      icon: '👔'
    },
    {
      key: 'food',
      label: 'Food',
      categories: ['food', 'food_stamps'],
      icon: '🥕'
    },
    {
      key: 'substance_abuse',
      label: 'Substance Abuse',
      categories: ['substance_abuse', 'addiction'],
      icon: '💊'
    },
    {
      key: 'medical',
      label: 'Medical',
      categories: ['medical'],
      icon: '🚑'
    },
    {
      key: 'mental_health',
      label: 'Mental Health',
      categories: ['mental_health', 'psych'],
      icon: '🧩'
    },
    {
      key: 'support_groups',
      label: 'Support Groups',
      categories: ['support_groups'],
      icon: '🤝',
    },
    {
      key: 'transportation',
      label: 'Transportation',
      categories: ['transportation'],
      icon: '🚌',
    },
    {
      key: 'covid',
      label: 'COVID-19',
      categories: ['covid'],
      icon: '😷'
    }
  ]
  return filters;
}

export default dashFilters();