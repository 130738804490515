import React from 'react'
import { Link } from 'react-router-dom'

const Header = () => {
  return (
    <header className="app-header">
      <div className="header-inner">
        <Link to="/" className="logo">
          <img src="main-logo.png" alt="Joynn"/>
        </Link>
      </div>
    </header>
  )
}

export default Header;