import React from 'react'

const CovidBanner = () => {
  return (
    <section className="covid-banner bg-yellow">
      <h2>COVID-19 Response</h2>
      <div className="left-right">
        <div className="left image">
          <img src="covid.svg" alt="covid19"/>
        </div>
        <div className="right content">
          <p>
          </p>
          <button className="learn-more">Learn More</button>
        </div>
      </div>
    </section>
  )
}

export default CovidBanner;