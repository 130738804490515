import React from 'react'

const Footer = () => {
  return (
    <footer className="footer">
      <p className="copywrite">
      © 2020 JOYNN, Creating The Continuum of Care
      </p>
    </footer>
  )
}

export default Footer;