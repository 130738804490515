import React, {useState, useEffect} from 'react';
import { Redirect } from 'react-router-dom';

import axios from 'axios';
const Consent = () => {
  const [consentData, setConsentData] = useState({
    consented: false,
    printedName: '',
  })
  const [finished, setFinished] = useState(false);
  useEffect(() => {
    if (consentData.printedName.length > 0) {
      setConsentData(c => ({...c, consented: true}))
    } else {
      setConsentData(c => ({...c, consented: false}))
    }
  }, [consentData.printedName])

  const submitConsent = (e) => {
    e.preventDefault();
    axios.post('consent-form', consentData, {headers: {
      'Authorization' : `Bearer ${localStorage.getItem('token')}`,
      }
    })
    .then((res) => {
      console.log(res.data);
      if (res.data.consent) {
        setFinished(true);
      }
    })
  }
  if (finished) {
    return <Redirect to="/form" />
  }

  return (
    <div className="consent-wrapper">
      <div className="consent-box white-box">
        <div className="consent-header">
          <h1>User Data Consent</h1>
          <p>All information provided to JOYNN staff remains confidential and will only be shared as
            needed within our agency. Some information collected on this sheet will be tracked and
            reported in “aggregate form” to our funders but no “identifying” information about you will be
            shared outside of our agency unless you have given written/signed permission to do so.
            </p>
        <h5>NOTE: ALL DOCUMENTATION IS FILED AND KEPT SECURED TO PROTECT YOUR
          INFORMATION
        </h5>
        </div>
        <form className="consent-form">
          <div className="input-block type-name">
            <label htmlFor="consent-name">Type your name</label>
            <input
            onInput={(e) => {setConsentData({...consentData, printedName: e.target.value})}}
            type="text" id="consent-name" />
          </div>
          <div className="input-block">
            <button
            onClick={submitConsent}
            className="consent-submit button-main">I agree</button>
          </div>
        </form>
      </div>
    </div>
  )
}

export default Consent;
