import React, {useState} from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import formData from '../utils/formData.js';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
const IntakeForm = () => {
  const [formGroups, setFormGroups] = useState([...formData()]);
  const [finished, setFinished] = useState(false);
  const updateFormData = (groupIndex, rowIndex, fieldIndex, value) => {
    let newData = [...formGroups];
    newData[groupIndex].rows[rowIndex].fields[fieldIndex].value = value;
    setFormGroups(newData);
  }

  const sendFormData = (e) => {
    e.preventDefault();
    const formatted = {};
    formGroups.map(g => {
      formatted[g.key] = {};
      g.rows.map(r => {
        r.fields.map(f => {
          formatted[g.key][f.key] = {
            value: f.value,
            label: f.label,
          }
        })
      })
    });
    axios.post('/form-submit', formatted, {
      headers: {
      'Authorization' : `Bearer ${localStorage.getItem('token')}`,
      }
    }).then(res => {
      console.log(res.data);
      setFinished(true);
    })
  }

  const formRows = (rows, groupIndex) => {
    return (
      rows.map((row, rowIndex) => {
        return (
          <div key={rowIndex} className="form-group-row">
            {formFields(row.fields, groupIndex, rowIndex)}
          </div>
        )
      })
    )
  }
  const OptionField = ({groupIndex, rowIndex, fieldIndex, field}) => {
    return (
      <select
      onChange={(e) => updateFormData(groupIndex, rowIndex, fieldIndex, ((e.target.value === 'true' || e.target.value === 'false') ? JSON.parse(e.target.value) : e.target.value))}
      id={field.key}
      value={field.value}>
        {field.options.map((opt, i) => <option key={opt.replace(' ', '-')} value={(field.values && field.values[i] !== 'undefined') ? field.values[i] : opt}>{opt}</option> )}
      </select>
    )
  }
  const formFields = (fields, groupIndex, rowIndex) => {
    return (
      fields.map((field, fieldIndex) => {
        if (typeof field.conditional === 'function' && !field.conditional()) {
          return null;
        }
        return (
          <div key={fieldIndex} className={`input-block ${field.type}`}>
            <label htmlFor={field.key}>{field.label}</label>
            {
              {
                'text': <input type="text" id={field.key} onInput={(e) => updateFormData(groupIndex, rowIndex, fieldIndex, e.target.value)}/>,
                'checkbox': <input type="checkbox" value={field.key} id={field.key} onInput={(e) => updateFormData(groupIndex, rowIndex, fieldIndex, e.target.checked)} />,
                'date': <DatePicker selected={field.value} onChange={(date) => updateFormData(groupIndex, rowIndex, fieldIndex, date)} />,
                'select': <OptionField groupIndex={groupIndex} rowIndex={rowIndex} fieldIndex={fieldIndex} field={field} />,
              }[field.type]
            }
          </div>
        )
      })
    )
  }

  const formParts = formGroups.map((group, groupIndex) => {
    return (
      <div key={groupIndex} className="form-group white-box">
        <h3>{group.title}</h3>
        <div className="form-group-fields">
          {formRows(group.rows, groupIndex)}
        </div>
      </div>
    )
  })
  if (finished) {
    return <Redirect to="/dashboard" />
  }
  return (
    <div className="intake-form-wrapper">
      <div className="intake-form-header">
        <h1>Client Intake Form</h1>
        <p>
        All information provided to JOYNN staff remains confidential and will only be shared as needed within our agency. Some information collected on this sheet will be tracked and reported in “aggregate form” to our funders but no “identifying” information about you will be shared outside of our agency unless you have given written/signed permission to do so.
        </p>
      </div>
      <form className="intake-form">
        {formParts}
        <button
        onClick={sendFormData}
        className="submit-form button-main">Submit</button>
      </form>
    </div>
  )
}

export default IntakeForm;
